import { HttpHeaders } from '@angular/common/http';

export const securityHeaders = new HttpHeaders({
	'Content-Type': 'application/json',
	'X-XSS-Protection': '1; mode=block',
	'X-Content-Type-Options': 'nosniff',
	'Content-Security-Policy': "default-src 'self'",
	'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
	'Permissions-Policy':
		'accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=()',
	'Cache-Control': 'no-store',
	'Referrer-Policy': 'strict-origin-when-cross-origin'
});

export const securityHeadersUpload = new HttpHeaders({
	Accept: '*/*',
	'X-XSS-Protection': '1; mode=block',
	'X-Content-Type-Options': 'nosniff',
	'Content-Security-Policy': "default-src 'self'",
	'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
	'Permissions-Policy':
		'accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=()',
	'Cache-Control': 'no-store',
	'Referrer-Policy': 'strict-origin-when-cross-origin'
});
