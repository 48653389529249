import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PModalConfirmComponent } from '@components/modals/p-modal-confirm/p-modal-confirm.component';
import { GoogleAuthService } from '@services/auth/google.service';

@Component({
	selector: 'app-modal-expired-session',
	standalone: true,
	imports: [CommonModule, PModalConfirmComponent],
	templateUrl: './modal-expired-session.component.html',
	styleUrls: ['./modal-expired-session.component.css']
})
export class ModalExpiredSessionComponent {
	constructor(private googleService: GoogleAuthService) {}

	onClose(): void {
		this.googleService.signOut();
	}
}
