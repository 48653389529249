import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PModalConfirmComponent } from '@components/modals/p-modal-confirm/p-modal-confirm.component';

@Component({
	selector: 'app-modal-error-server',
	standalone: true,
	imports: [CommonModule, PModalConfirmComponent],
	templateUrl: './modal-error-server.component.html',
	styleUrls: ['./modal-error-server.component.css']
})
export class ModalErrorServerComponent {
	constructor() {}
}
