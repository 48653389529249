import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
class PermissionsService {
	constructor(private _router: Router) {}

	canActivate(): boolean {
		const user = localStorage.getItem('user');
		if (!user || user == '') {
			this._router.navigate(['/login']);
			return false;
		}
		return true;
	}
}

export const AuthGuard: CanActivateFn = (): boolean => {
	return inject(PermissionsService).canActivate();
};
