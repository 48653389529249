<div
	*ngIf="isVisible"
	class="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
>
	<div class="relative z-50">
		<div class="fixed top-0 left-0 flex items-center justify-center w-full h-full">
			<div [class]="sizeClass" class="p-4 bg-white shadow-md rounded-2xl">
				<div class="">
					<span class="flex justify-end material-symbols-sharp opacity-[.60] cursor-pointer" (click)="cancel()">
						close
					</span>
					<div class="flex justify-center">
						<div class="text-center">
							<div class="text-center">
								<div *ngIf="icon === 'done'" class="flex justify-center w-8 h-8 mx-auto rounded-full bg-success-medium">
									<span class="mt-1 text-white">
										<span class="material-symbols-sharp"> done </span>
										<span class="material-symbols-sharp"> error </span>
									</span>
								</div>
								<div
									*ngIf="icon !== 'done'"
									class="flex justify-center w-8 h-8 mx-auto rounded-full bg-critical-medium"
								>
									<span class="mt-[4px] text-white ">
										<span *ngIf="icon === 'error'" class="material-symbols-sharp"> error </span>
										<span *ngIf="icon === 'warning'" class="material-symbols-sharp"> warning </span>
									</span>
								</div>
							</div>

							<h2 class="mt-2 text-lg font-semibold text-zinc-700">{{ title }}</h2>
							<p class="mt-2 mb-4 text-neutral-600 text-md">{{ subtitle }}</p>
						</div>
					</div>
				</div>

				<div class="flex justify-center">
					<p-button [size]="'large'" (click)="cancel()">{{ titleButton }}</p-button>
				</div>
			</div>
		</div>
	</div>
</div>
