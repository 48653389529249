import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ModalServerService {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {}

	openModal(component: any) {
		const componentRef: any = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

		this.appRef.attachView(componentRef.hostView);
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);

		componentRef.onDestroy(() => {
			this.appRef.detachView(componentRef.hostView);
			componentRef.destroy();
		});

		return {
			close: () => {
				document.body.removeChild(domElem);
				componentRef.destroy();
			}
		};
	}
}
