import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'p-loader-circle',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './p-loader-circle.component.html',
	styleUrls: ['./p-loader-circle.component.css']
})
export class PLoaderCircleComponent {
	@Input() type: 'primary' | 'secondary' | 'tertiary' = 'primary';
	@Input() size: 'large' | 'medium' | 'small' | 'x-small' = 'small';

	get classLoader() {
		return `load-${this.size}  mt-0 text-${this.type}-ligth animate-spin items-center flex`;
	}
}
