import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthService } from '@services/auth/user-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private userAuthService: UserAuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const defaultRequest = request;

		const token = this.userAuthService.getToken();

		if (token == '') {
			return next.handle(defaultRequest);
		}

		const authRequest = defaultRequest.clone({
			setHeaders: {
				Authorization: 'Bearer ' + token
			}
		});

		return next.handle(authRequest);
	}
}
