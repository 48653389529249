import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ModalServerService } from './modals/modal-server.service';
import { ControlErrorModalService } from './control-error-modal.service';
import { ModalExpiredSessionComponent } from './modals/modal-expired-session/modal-expired-session.component';
import { ModalNotAuthComponent } from './modals/modal-not-auth/modal-not-auth.component';
import { ModalErrorServerComponent } from './modals/modal-error-server/modal-error-server.component';
import { ModalErrorNotFoundComponent } from './modals/modal-error-not-found/modal-error-not-found.component';

@Injectable()
export class ServerInterceptor implements HttpInterceptor {
	isOpenModal = false;

	constructor(
		private modalService: ModalServerService,
		private controlModal: ControlErrorModalService
	) {
		this.controlModal.modalState.subscribe({
			next: (state) => {
				this.isOpenModal = state;
			}
		});
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (this.isOpenModal) {
					this.controlModal.close();
					return throwError(() => error);
				}
				this.controlModal.open();
				if (error.status === 500 || error.status === 503) {
					this.modalService.openModal(ModalErrorServerComponent);
				} else if (error.status === 401 && !request.url.includes('auth/google')) {
					this.modalService.openModal(ModalExpiredSessionComponent);
				} else if (error.status === 403) {
					this.modalService.openModal(ModalNotAuthComponent);
				}
				/* else if (error.status === 400) {
					this.modalService.openModal(ModalErrorNotFoundComponent);
				} */

				return throwError(() => error);
			})
		);
	}
}
