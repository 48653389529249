import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ControlErrorModalService {
	modalState = new Subject<boolean>();

	constructor() {}

	open() {
		this.modalState.next(true);
	}

	close() {
		this.modalState.next(false);
	}
}
