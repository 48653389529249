import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadComponent: () => import('./pages/login-page/login-page.component').then((m) => m.LoginPageComponent),
		canActivate: [LoginGuard]
	},

	{
		path: 'google',
		loadComponent: () => import('./pages/token-google/token-google.component').then((m) => m.TokenGoogleComponent)
	},

	{
		path: '**',
		loadComponent: () => import('./pages/notfound-page/notfound-page.component').then((m) => m.NotfoundPageComponent)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
