import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuthService } from './user-auth.service';
import { securityHeaders } from '@services/http-headers.config';

@Injectable({
	providedIn: 'root'
})
export class ApiAuthService {
	private urlApi: string = environment.apiUrl + 'auth';
	constructor(
		private http: HttpClient,
		private userAuthService: UserAuthService
	) {}

	signInGoogle(token: string): Observable<any> {
		const direction = this.urlApi + '/google';
		const headers = securityHeaders.set('g-auth-token', token);

		return this.http.post(direction, {}, { headers });
	}

	signOut(): Observable<any> {
		const direction = this.urlApi + '/sign-out';
		const headers = securityHeaders.set('Authorization', 'Bearer ' + this.userAuthService.getToken());

		return this.http.post(direction, {}, { headers });
	}
}
