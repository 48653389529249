import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SocialLoginModule, SocialAuthServiceConfig, GoogleInitOptions } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ServerInterceptor } from './core/interceptors/server.interceptor';

const googleLoginOptions: GoogleInitOptions = {
	oneTapEnabled: false
};
@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, AppRoutingModule, SocialLoginModule, HttpClientModule],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleClientID, googleLoginOptions)
					}
				]
			} as SocialAuthServiceConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},

		{
			provide: HTTP_INTERCEPTORS,
			useClass: ServerInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
